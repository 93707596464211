import { SelectCloudRedGuids } from '@cx/x-product'
import { selectEmail, useAuthStore, selectAccessToken } from '@nike/aegis-auth-react'
import { Divider } from '@nike/eds'
import { useState } from 'react'

export function SelectCloudRedGuidsExample() {
  const authStore = useAuthStore()
  const userEmail = selectEmail(authStore)
  const accessToken = selectAccessToken(authStore)
  let defaultSelectedGuid = {
    value: 'c78d0ad7-8dcf-42fe-9a2b-efc747bc8aca',
    label: 'c78d0ad7-8dcf-42fe-9a2b-efc747bc8aca',
  }
  defaultSelectedGuid = undefined
  const [selectedGuid, setSelectedGuid] = useState(defaultSelectedGuid)
  const selectedGuidVal = (Array.isArray(selectedGuid) ? selectedGuid : [selectedGuid]).filter(
    (v) => !!v
  )
  if (!SelectCloudRedGuids) return null
  return (
    <div className='mfe-selectcloudredguids'>
      <SelectCloudRedGuids
        env='qa'
        userEmail={userEmail}
        accessToken={accessToken}
        value={selectedGuid}
        onChange={setSelectedGuid}
      />
      <Divider className='eds-spacing--mt-24 eds-spacing--mb-24' />
      <p>
        Selected guid
        {selectedGuidVal.length
          ? ` (${selectedGuidVal.length}): ${JSON.stringify(
              selectedGuidVal.map(({ value }) => value)
            )}`
          : ': N/A'}
      </p>
    </div>
  )
}
