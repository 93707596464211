export const platformConsoleAPI = 'https://api-dev-console.platforms.nike.com/graphql'

const baseConfig = {
  platformConsoleAPI,
  mfeUrlBase: 'https://mfe-dev.console-test.nikecloud.com',
}

const dev = {
  ...baseConfig,
}

const preprod = {
  ...baseConfig,
  mfeUrlBase: 'https://mfe-preprod.console-test.nikecloud.com',
}

const prod = {
  ...baseConfig,
  mfeUrlBase: 'https://mfe.console-prod.nikecloud.com',
}

let config = dev

if (window.origin.includes('mfe-preprod.console-test')) {
  config = preprod
} else if (window.origin.includes('mfe.console-prod')) {
  config = prod
}

export default config
