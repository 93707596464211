import { AegisClient } from '@nike/aegis-auth-react'
import { navigate } from 'raviger'

const aegisClient = new AegisClient({
  clientId: 'nike.cx.mfe-viewer',
  redirectUri: window.location.origin,
  responseType: 'id_token token',
  scopes: ['profile', 'openid', 'email', 'offline_access'],
  storageNamespace: 'mfe-viewer',
  qa: true,
  restoreOriginalUri: (oktaAuth, originalUri) => {
    if (!originalUri) {
      originalUri = oktaAuth.options.redirectUri
    }
    navigate(originalUri)
  },
})
export default aegisClient
