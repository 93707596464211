export const CHART_TYPES = {
  AreaChart: 'AreaChart',
  BarChart: 'BarChart',
  LineChart: 'LineChart',
  PieChart: 'PieChart',
  RadarChart: 'RadarChart',
  StackedBarChart: 'StackedBarChart',
  StackedAreaChart: 'StackedAreaChart',
  ScatterChart: 'ScatterChart',
  BigNumberChart: 'BigNumberChart',
  ListChart: 'ListChart',
  Table: 'Table',
  Calendar: 'Calendar',
}

const mockChartData = (chartType) => ({
  xAxisLabel: 'x label',
  yAxisLabel: 'y label',
  legend: true,
  withBrush: true,
  value: {
    type: chartType,
    data: [
      {
        name: 'Ni-fi',
        value: 2400,
        value2: 1110,
        value3: 1220,
        value4: 1120,
        drillDownUrl: 'https://console-dev.platforms.nike.com/nifi',
      },
      {
        name: 'Midsole',
        value: 4567,
        value2: 2145,
        value3: 2310,
        value4: 1210,
        drillDownUrl: 'https://console-dev.platforms.nike.com/midsole',
      },
      {
        name: 'NSP',
        value: 5398,
        value2: 1290,
        value3: 1110,
        value4: 4510,
        drillDownUrl: 'https://console-dev.platforms.nike.com/nsp',
      },
      {
        name: 'Cerberus',
        value: 6398,
        value2: 5000,
        value3: 3210,
        value4: 210,
        drillDownUrl: 'https://console-dev.platforms.nike.com/cerberus',
      },
      {
        name: 'NSP3',
        value: 539,
        value2: 5200,
        value3: 210,
        value4: 130,
        drillDownUrl: 'https://console-dev.platforms.nike.com/nsp',
      },
    ],
  },
})

const mockedScatterWidgetData = {
  xAxisLabel: 'x label',
  yAxisLabel: 'y label',
  legend: ['value1', 'value2', 'value3', 'value4'],
  withBrush: true,
  value: {
    type: CHART_TYPES.ScatterChart,
    data: [
      [
        { x: 100, y: 200 },
        { x: 120, y: 100 },
        { x: 170, y: 300 },
        { x: 140, y: 250 },
      ],
      [
        { x: 150, y: 400 },
        { x: 110, y: 280 },
      ],
      [
        { x: 200, y: 400 },
        { x: 120, y: 280 },
      ],
      [
        { x: 130, y: 0 },
        { x: 75, y: 70 },
        { x: 100, y: 100 },
      ],
    ],
  },
}

const mockedTableWidgetData = {
  value: {
    type: CHART_TYPES.Table,
    data: [...Array(10)].map((_, i) => ({
      Id: i + 1,
      Value: Math.random().toFixed(2),
      Sum: Math.random().toFixed(2),
      Details: {
        name: 'More...',
        tooltip: [
          {
            label: 'key1',
            value: 'value1',
          },
          {
            label: 'key2',
            value: 'value2',
          },
        ],
      },
    })),
  },
}

const mockedBigNumberWidgetData = {
  value: {
    type: CHART_TYPES.BigNumberChart,
    data: [
      { number: 13.1, unit: 'k', additionalInformation: 'Total User Visits' },
      { number: 1.64, precedingUnit: '$', additionalInformation: 'Per User' },
      { number: 100, unit: '%', additionalInformation: 'Per User' },
    ],
  },
}

const mockedListWidgetData = {
  value: {
    type: CHART_TYPES.ListChart,
    data: [
      {
        name: 'Expandable item',
        details: [
          {
            value: 'content',
            label: 'label 1',
          },
          {
            value: 'content',
            label: 'label 2',
          },
        ],
      },
      { name: 'NPE', drillDownUrl: 'https://github.com/nike-cx/npe-client' },
      { name: 'Platform Console', drillDownUrl: 'https://github.com/nike-cx/platform-console/' },
      { name: 'Nifi', drillDownUrl: 'https://github.com/nike-cx/nifi-ui' },
      { name: 'Midsole', drillDownUrl: 'https://github.com/nike-cx/midsole-client' },
    ],
  },
}

const mockedCalendarWidgetData = {
  value: {
    type: CHART_TYPES.Calendar,
    data: [
      {
        name: '1 Event Example',
        start: '2024-11-22T10:15:30Z',
        end: '2024-11-22T10:25:30Z',
        physicalLocation: 'Physical location Example 1',
      },
      {
        name: '2 Event Example',
        start: '2024-11-22T10:15:30Z',
        end: '2024-11-23T11:15:30Z',
        physicalLocation: 'PTC HUB',
        virtualLocation: 'https://example.com',
        drillDownUrl: 'https://example.com',
      },
      {
        name: '3 Event Example',
        start: '2024-11-22T10:15:30Z',
        physicalLocation: 'Physical location Example 3',
        end: '2024-11-22T14:34:30Z',
        virtualLocation: 'https://example.com',
        drillDownUrl: 'https://example.com',
      },
      {
        name: '4 Event Example',
        start: '2024-11-22T10:15:30Z',
        physicalLocation: 'Physical location Example 4',
        end: '2024-11-22T18:34:30Z',
        virtualLocation: 'https://example.com',
        drillDownUrl: 'https://example.com',
      },
      {
        name: '5 Event Example',
        start: '2024-11-23T10:15:30Z',
        end: '2024-11-23T14:34:30Z',
        virtualLocation: 'https://example.com',
        drillDownUrl: 'https://example.com',
      },
      {
        name: '6 Event Example',
        start: '2024-11-15T10:15:30Z',
        end: '2024-11-15T17:15:30Z',
        physicalLocation: 'Physical location Example 6',
      },
    ],
  },
}

export const renderWidgetData = (chartType) => {
  switch (chartType) {
    case CHART_TYPES.AreaChart:
    case CHART_TYPES.BarChart:
    case CHART_TYPES.LineChart:
    case CHART_TYPES.PieChart:
    case CHART_TYPES.RadarChart:
    case CHART_TYPES.StackedAreaChart:
    case CHART_TYPES.StackedBarChart:
      return mockChartData(chartType)
    case CHART_TYPES.ScatterChart:
      return mockedScatterWidgetData
    case CHART_TYPES.Table:
      return mockedTableWidgetData
    case CHART_TYPES.BigNumberChart:
      return mockedBigNumberWidgetData
    case CHART_TYPES.ListChart:
      return mockedListWidgetData
    case CHART_TYPES.Calendar:
      return mockedCalendarWidgetData
    default:
      break
  }
}
