import { Box, Button, Card, Text } from '@nike/eds'
import { navigate } from 'raviger'

export function NotFound() {
  return (
    <div className='mfe-page-container'>
      <Card>
        <Box className='mfe-content-card'>
          <Text font={'title-5'} as={'h4'}>
            Oops...it looks like there's nothing here.
          </Text>
          <Button className='eds-spacing--mt-24' onClick={() => navigate('/')}>
            Back to Home
          </Button>
        </Box>
      </Card>
    </div>
  )
}
