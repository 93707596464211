// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mfe-widget {
  padding: 25px 100px;
  background-color: var(--eds-color-grey-4);
}
.mfe-widgets-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-auto-flow: dense;
  grid-auto-rows: min-content max-content auto;
  grid-gap: var(--eds-space-16);
  place-items: stretch;
}
`, "",{"version":3,"sources":["webpack://./src/views/widget/styles.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,yCAAyC;AAC3C;AACA;EACE,aAAa;EACb,4DAA4D;EAC5D,qBAAqB;EACrB,4CAA4C;EAC5C,6BAA6B;EAC7B,oBAAoB;AACtB","sourcesContent":[".mfe-widget {\n  padding: 25px 100px;\n  background-color: var(--eds-color-grey-4);\n}\n.mfe-widgets-grid {\n  display: grid;\n  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));\n  grid-auto-flow: dense;\n  grid-auto-rows: min-content max-content auto;\n  grid-gap: var(--eds-space-16);\n  place-items: stretch;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
