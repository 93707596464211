// NOTE: the MFE Viewer can only render this component if all external dependencies
// required here have been added to ./mfe-viewer/app/package.json, not to this
// MFE package.
import { Form } from '@cx/form'
import { useState } from 'react'
import { ToastContainer } from 'react-toastify'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { selectAccessToken, useAuthStore } from '@nike/aegis-auth-react'
import { Button, Card, Radio, RadioGroup, Select, TextField } from '@nike/eds'

import { Intro } from './intro'
import mockMiscFields from './mocks/misc-fields.json'
import mockXEnzoAviatrix from './mocks/xenzos-aviatrix.json'

function getEnv(origin) {
  switch (true) {
    case origin.includes('localhost'):
    case origin.includes('mfe-dev.console-test.nikecloud.com'):
      return 'qa'
    case origin.includes('mfe-preprod.console-test.nikecloud.com'):
    case origin.includes('mfe.console-prod.nikecloud.com'):
    default:
      return 'prod'
  }
}

export function FormExamples() {
  const env = getEnv(window.location.origin)
  const authStore = useAuthStore()
  const accessToken = selectAccessToken(authStore)
  const [dataSource, setDataSource] = useState('aviatrix')
  const [domain, setDomain] = useState('pe01.npe-jc-test.nikecloud.com')
  const [configName, setConfigName] = useState('xenzos-aviatrix')
  const [actionType, setActionType] = useState('create')
  const [testConfig, setTestConfig] = useState(mockXEnzoAviatrix.config)
  const [formKey, setFormKey] = useState(1)

  return (
    <div className='mfe-page-container'>
      <div className='mfe-content-card'>
        <Intro />
      </div>

      <div className='demo-controls mfe-content-card'>
        <div className='eds-spacing--m-48'>
          <RadioGroup
            id='dataSource'
            name='dataSource'
            label='Sample Forms'
            onChange={(e) => {
              setDataSource(e.target.value)
              switch (e.target.value) {
                case 'aviatrix':
                  setConfigName('xenzos-aviatrix')
                  return setTestConfig(mockXEnzoAviatrix.config)
                case 'mockMisc':
                  return setTestConfig(mockMiscFields.config)
                case 'proxy':
                  return setTestConfig(null)
                default:
                  return
              }
            }}
            valueSelected={dataSource}
          >
            <Radio label='XEnzo Aviatrix create form (local file)' value='aviatrix' id='aviatrix' />
            {/* <Radio label='XEnzo AWS create form (local file)' value='aws' id='aws' /> */}
            <Radio label='Form with Show-If and other widgets' value='mockMisc' id='mockMisc' />
            <Radio label='Fetch form from a CPaaS Proxy' value='proxy' id='proxy' />
          </RadioGroup>

          {dataSource === 'proxy' && (
            <div className='eds-spacing--p-48 eds-flex eds-flex--direction-column eds-gap--24'>
              <TextField
                name='domain'
                label='CPaaS Proxy Domain'
                onChange={(e) => setDomain(e.target.value)}
                value={domain}
              />
              <TextField
                name='configName'
                label='Name of Config'
                onChange={(e) => setConfigName(e.target.value)}
                value={configName}
              />

              <Select
                name='actionType'
                label='Action'
                options={[
                  { label: 'create', value: 'create' },
                  { label: 'update', value: 'update' },
                  { label: 'delete', value: 'delete' },
                ]}
                onChange={(selected) => setActionType(selected.value)}
                value={{ label: actionType, value: actionType }}
              />

              <Button onClick={() => setFormKey(formKey + 1)}>
                {formKey > 1 ? 'Fetch again' : 'Fetch'}
              </Button>
            </div>
          )}
        </div>

        <Card>
          <Form
            key={`Form-${formKey}`}
            accessToken={accessToken}
            env={env}
            domain={domain}
            name={configName}
            action={actionType}
            testConfig={testConfig}
            onError={OnError}
            onSuccess={OnSuccess}
            showDevControls
          />
        </Card>
      </div>
      <ToastContainer
        autoClose={8000}
        hideProgressBar={true}
        position='top-center'
        stacked
        style={{ minWidth: '600px', marginTop: '75px' }}
      />
    </div>
  )
}

function OnError({ error }) {
  toast.error(`Something went wrong. ${error}`)
}

function OnSuccess() {
  toast.success(`Form was submitted.`)
}
