import { AppHeader, Link, Select, Text } from '@nike/eds'
import { NikeApp } from '@nike/nike-design-system-icons'
import { Link as RavigerLink, navigate } from 'raviger'

export function TopNav({ mfeNamespace, mfeList, commandBarSlot, ...props }) {
  return (
    <AppHeader
      appName='CX MFE viewer'
      logoSlot={<NikeApp color='black' width='56px' height='64px' />}
      className='mfe-header'
      toolbarSlot={
        <div className='mfe-select'>
          <Select
            id='ts-dropdown'
            placeholder='Select MFE'
            options={mfeList?.map((mfe) => ({ label: mfe, value: `/${mfe}` }))}
            value={mfeNamespace ? { label: mfeNamespace, value: mfeNamespace } : undefined}
            onChange={(selected) => navigate(selected.value)}
          />
        </div>
      }
      {...props}
      commandBarSlot={
        <div className='mfe-command-bar'>
          <div className='mfe-breadcrumbs'>
            <Link as={RavigerLink} href='/'>
              Home
            </Link>
            {mfeNamespace && (
              <>
                {'>'}
                <Text>{mfeNamespace}</Text>
              </>
            )}
          </div>
          {commandBarSlot}
        </div>
      }
    />
  )
}
