import { MFEViewerContext } from '../../root.component'
import { platformConsoleAPI } from '../../util/config'
import { HomePage } from '@cx/tech-solution-homepage'
import { useAuthStore, selectAccessToken } from '@nike/aegis-auth-react'
import { Select } from '@nike/eds'
import axios from 'axios'
import { useEffect, useContext, useState } from 'react'

export function HomePageExample() {
  const {
    viewerState: { techSolutionId },
  } = useContext(MFEViewerContext)
  const authStore = useAuthStore()
  const accessToken = selectAccessToken(authStore)

  return (
    <HomePage
      techSolutionId={
        localStorage.getItem('techSolutionId')
          ? localStorage.getItem('techSolutionId')
          : techSolutionId
      }
      accessToken={accessToken}
      env='dev'
    />
  )
}

export function TSSelector() {
  const { setViewerState, viewerState } = useContext(MFEViewerContext)
  const authStore = useAuthStore()
  const accessToken = selectAccessToken(authStore)
  const [options, setOptions] = useState([])
  useEffect(() => {
    const getHomepageData = async (accessToken) => {
      const query = `
        query TechSolutions {
          techSolutions(teams: "") {
            name
            id
        }
      }`

      const tsSelectData = await axios
        .post(platformConsoleAPI, { query }, { headers: { Authorization: accessToken } })
        .then(({ data }) => {
          return data.data?.techSolutions?.map(({ name, id }) => ({ label: name, value: id }))
        })
      if (tsSelectData) {
        setOptions(tsSelectData)
      }
    }

    if (accessToken) {
      getHomepageData(accessToken)
    }
  }, [accessToken])

  return (
    <div className='mfe-select toolbar-select'>
      <Select
        id='ts-dropdown'
        isOpen
        options={options}
        onChange={(selected) => {
          setViewerState({ ...viewerState, techSolutionId: selected.value })
          localStorage.setItem('techSolutionId', selected.value)
        }}
        value={options.find((opt) => opt.value === viewerState.techSolutionId)}
      />
    </div>
  )
}
