import { Text } from '@nike/eds'

export function Intro() {
  return (
    <>
      <Text as='h1' className='eds-spacing--my-16'>
        @cx/form
      </Text>
      <Text>A micro frontend that enables self-service developer platforms.</Text>

      <Text as='h3' className='eds-spacing--my-16'>
        Generic user facing forms framework
      </Text>
      <Text>
        This component can be used in any application as a way to provide *creation* forms. The form
        definition may be hard-coded in the application or fetched from an API endpoint.
      </Text>

      <Text as='h3' className='eds-spacing--my-16'>
        Crossplane-specific use case
      </Text>

      <Text>
        Platform teams define composite resource definitions (XRDs) which result in automatically
        generated APIs for managing these resources. The platform teams can then offer forms that
        provide payloads for these Crossplane APIs.
      </Text>
    </>
  )
}
