export const WIDGET = {
  colorPalette: 'categorical',
  dataSourceURL: 'https://www.chart.com/data',
  dataQuery: null,
  description:
    'The NewRelic Console Metric Downloader is a downloadable tool that enables users to retrieve recent data from NewRelic, dashboard, and other API endpoints. It enhances your data analysis capabilities and allows for informed decision-making based on the latest information.',
  examples: [
    {
      name: 'example 1',
      description: 'Pie chart showing users per Tech Solution',
      data: '{"name":"Users per Tech Solution","xAxisLabel":"Tech Solution","yAxisLabel":"Amount of users","moreInfoURL":"https://google.com","maintainerEmail":"user@nike.com","withBrush":false,"value":{"type":"PieChart","data":[{"name":"Ni-fi","value":2400,"drillDownUrl":"https://console-dev.platforms.nike.com/nifi"},{"name":"Midsole","value":4567,"drillDownUrl":"https://console-dev.platforms.nike.com/midsole"},{"name":"NSP","value":5398,"drillDownUrl":"https://console-dev.platforms.nike.com/nsp"}]}}',
    },
  ],
  isFavorite: false,
  lifecycleState: 'release',
  maintainerEmail: 'Jonh.Doe.ADGroup',
  maintainerSlack: 'https://nikedigital.slack.com/archives/123',
  moreInfoURL: 'https://example.com',
  name: 'Widget name',
  refreshFrequency: 3600,
  scope: 'user',
  size: 'smallSquare',
  tags: ['tag', 'tag2'],
  techSolutionIds: ['975f48b8-c925-4059-b225-e47e206dc93b', 'Xpt3x69qJeO0gaZ_ZZc0F'],
  type: 'api',
  updatedOn: 1683735227941,
  updatedBy: 'john.doe@nike.com',
  version: '1.0',
}

export const renderWidget = (chartType) => ({
  ...WIDGET,
  name: `${chartType} widget`,
})
