import './styles.css'
import { ErrorPage, NotFoundPage, UnauthorizedPage, ForbiddenPage } from '@cx/error-page'

export function ErrorPageExample() {
  return (
    <div className='error-page-container'>
      <ErrorPage errorCode={500} errorMessage='Internal Server Error' />

      <UnauthorizedPage />
      <NotFoundPage />
      <ForbiddenPage />
      <ErrorPage
        slackConfig={{
          channelName: '#platformconsole',
          channelUrl: 'https://nikedigital.slack.com/archives/CC5FN7AMV',
        }}
      />
      <NotFoundPage
        slackConfig={{
          channelName: '#developerportal',
          channelUrl: 'https://nikedigital.slack.com/archives/CC5FN7AMV',
        }}
      />
    </div>
  )
}
