import { Home } from '../components/Home'
import { NotFound } from '../components/NotFound'
import { ErrorPageExample } from './error-page'
import { HomePageExample, TSSelector } from './tech-solution-homepage'
import { Widget } from './widget'
import { XProduct } from './x-product'

export const mfeList = ['@cx/error-page', '@cx/tech-solution-homepage', '@cx/widget', '@cx/x-product']

export const mfeView = (pathname) => {
  switch (pathname) {
    case '/':
      return <Home />
    case '/@cx/error-page':
      return <ErrorPageExample />
    case '/@cx/tech-solution-homepage':
      return <HomePageExample />
    case '/@cx/widget':
      return <Widget />
    case '/@cx/x-product':
      return <XProduct />
    default:
      return <NotFound />
  }
}

export const mfeTopNav = (mfeNamespace) => {
  switch (mfeNamespace) {
    case '@cx/tech-solution-homepage':
      return <TSSelector />
    default:
      return undefined
  }
}
