import { mfeList } from '../views'
import { Box, Button, Card, Text } from '@nike/eds'
import { navigate } from 'raviger'

export function Home() {
  return (
    <div className='mfe-components-div mfe-page-container'>
      {mfeList.map((mfe, i) => {
        return (
          <div key={i}>
            <Card>
              <Box className='mfe-components-box'>
                <Text font={'title-5'} as={'h4'}>
                  {mfe.split('/')[1]}
                </Text>
                <Button onClick={() => navigate(mfe)}>View</Button>
              </Box>
            </Card>
          </div>
        )
      })}
    </div>
  )
}
