import Root from './root.component'
import '@nike/eds/dist/index.css'
import React from 'react'
import ReactDOM from 'react-dom'
import singleSpaReact from 'single-spa-react'

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  renderType: 'createRoot',
  rootComponent: (props) => <Root {...props} />,
  errorBoundary(err, info, props) {
    console.warn(`Viewer Error: ${err.message}`)
    return null
  },
})

export const { bootstrap, mount, unmount } = lifecycles
