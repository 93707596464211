import aegisClient from './auth/client'
import { TopNav } from './components/TopNav'
import './styles.css'
import { mfeList, mfeView, mfeTopNav } from './views'
import { LoginRequired, useAuthStore, selectIsLoggedIn, useAuth } from '@nike/aegis-auth-react'
import { Spinner } from '@nike/eds'
import { usePath } from 'raviger'
import { useCallback, createContext, useState, memo } from 'react'

// Put any shared data here
export const MFEViewerContext = createContext({})

const Root = memo(() => {
  const [viewerState, setViewerState] = useState({
    techSolutionId: localStorage.getItem('techSolutionId') || '',
  })

  const pathname = usePath()
  const client = useAuth(aegisClient)
  const isLoggedIn = useAuthStore(selectIsLoggedIn)

  const mfeNamespace = mfeList.find((mfe) => mfe === pathname?.slice(1))

  if (!isLoggedIn) {
    return <Spinner size='large' />
  }

  return (
    <LoginRequired client={client}>
      <MFEViewerContext.Provider value={{ viewerState, setViewerState }}>
        <TopNav
          mfeList={mfeList}
          mfeNamespace={mfeNamespace}
          commandBarSlot={<CommandBar mfeNamespace={mfeNamespace} />}
        />
        <MFE pathname={pathname} />
      </MFEViewerContext.Provider>
    </LoginRequired>
  )
})

const MFE = memo(({ pathname }) => {
  const getMfeView = useCallback(() => mfeView(pathname), [pathname])
  return <div className='mfe-main'>{getMfeView()}</div>
})

function CommandBar({ mfeNamespace }) {
  // Sets additional content in command bar (secondary nav)
  // next to version dropdown if an mfe needs it
  return <div className='command-bar-aside'>{mfeTopNav(mfeNamespace)}</div>
}

export default memo(Root)
